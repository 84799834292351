<template>
  <div class="menu-container" v-loading="!defaultActive">
    <el-menu
      ref="elMenu"
      :default-active="defaultActive"
      :background-color="(currentTheme || {}).menuBackgroundColor"
      :text-color="(currentTheme || {}).menuTextColor"
      :active-text-color="isMobileScreen?'#0F376A':'#fff'"
      :unique-opened="true"
      :collapse="collapse"

    >
      <template v-for="menu in leftMenulist">
        <el-submenu
          v-if="menu.children.length && menu.enable"
          :key="menu.name"
          :index="menu.id"
        >
          <template slot="title">
            <em :class="menu.icon"></em>
            <span>{{ $t(menu.name) }}</span>
          </template>
          <template v-for="item in menu.children">
            <el-menu-item
              v-if="item.enable"
              :key="item.id"
              :index="`/admin/${item.router}`"
              @click="$emit('toCurrentPage',item)"
            >
              <div class="item">
                <em :class="item.icon"></em>
                <span>{{ $t(item.name) }}</span>
              </div>
            </el-menu-item>
          </template>
        </el-submenu>
        <el-menu-item
          v-if="!menu.children.length && menu.enable"
          :key="menu.id"
          :index="`/admin/${menu.router}`"
          @click="$emit('toCurrentPage',menu)"
        >
          <em :class="menu.icon"></em>
          <span slot="title">
            {{ $t(menu.name) }}
          </span>
        </el-menu-item>
      </template>
    </el-menu>


   <div style="padding-left:30px;">
    <span style="color: #303133;font-size:12px ">Version: 1.15.1</span>
   </div>
  </div>
</template>

<script>

import { mapState } from "vuex";
export default {
  name: "LeftMenu",
  props: {
    leftMenulist:{
      type: Array,
      default: []
    },
    currentTheme:{
      type: Object,
      required: true
    },
    collapse:{
      type: Boolean,
      required: false
    },
    // defaultActive:defaultActive

  },
  computed:{
    ...mapState({
      isMobileScreen: "isMobileScreen",
    }),
    defaultActive() {
      return this.$route.path;
    },
  }

};
</script>

<style lang="scss" scoped>
@import "../../index.scss";

</style>
